import axios from 'axios'

// axios.defaults.baseURL = ' ';
// axios.defaults.baseURL = 'http://101.42.168.195:5500';
axios.defaults.baseURL = 'http://47.108.78.135:5500';
// axios.defaults.baseURL = 'http://localhost:5500';
axios.interceptors.request.use(function (config) { //当所有axios发送请求的时候进行拦截
    //获取token
    let token = sessionStorage.getItem('token');
    //有没有token,有的话就加在request里面
    token && (config.headers['token'] = token);
    return config;
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {//所有axios的响应进行拦截
    return response;
}, function (error) {
    if (error.response.status == 401) { //token失效了
        sessionStorage.removeItem('token');
        window.location.href = '/';
    }
    return Promise.reject(error);
});


export default axios;