<template>
<div>
  <!-- <div id="appLoading">
      <div class='loading'></div>
  </div> -->
  <!-- app的样式style="display: none" -->
  <div id="app" >
    <router-view />
  </div>
</div>
  
</template>
<script>

export default {
  data(){
    return{

    }
  },
  mounted(){
    // document.getElementById('app').style.display = 'block';
	//   document.getElementById('appLoading').style.display = 'none';
  }
}
</script>


<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  height: 100%;
  font-size: 20px;
  font-family: 'Microsoft YaHei';
}

// loading的全局样式
.loading{

    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;;
    opacity: 0.8;
}

.loading.fixed {
    position: fixed;
}

.loading:before {
      content: '';
      background-color: rgba(0,0,0,0);
      border: 5px solid rgba(0,183,229,0.9);
      opacity: .9;
      border-right: 5px solid rgba(0,0,0,0);
      border-left: 5px solid rgba(0,0,0,0);
      border-radius: 50px;
      box-shadow: 0 0 35px #2187e7;
      width: 50px;
      height: 50px;
      -moz-animation: spinPulse 1s infinite ease-in-out;
      -webkit-animation: spinPulse 1s infinite linear;
      margin: -25px 0 0 -25px;
      position: absolute;
      top: 50%;
      left: 50%;
  }

.loading:after {
      content: '';
      background-color: rgba(0,0,0,0);
      border: 5px solid rgba(0,183,229,0.9);
      opacity: .9;
      border-left: 5px solid rgba(0,0,0,0);
      border-right: 5px solid rgba(0,0,0,0);
      border-radius: 50px;
      box-shadow: 0 0 15px #2187e7;
      width: 30px;
      height: 30px;
      -moz-animation: spinoffPulse 1s infinite linear;
      -webkit-animation: spinoffPulse 1s infinite linear;

      margin: -15px 0 0 -15px;
      position: absolute;
      top: 50%;
      left: 50%;
}
@-moz-keyframes spinPulse {

    0% {

        -moz-transform:rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #2187e7;
    }
    50% {

        -moz-transform: rotate(145deg);
        opacity: 1;
    }
    100% {

        -moz-transform: rotate(-320deg);
        opacity: 0;
    }
}
@-moz-keyframes spinoffPulse {

    0% {

        -moz-transform: rotate(0deg);
    }
    100% {

        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spinPulse {

    0% {

        -webkit-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #2187e7;
    }
    50% {

        -webkit-transform: rotate(145deg);
        opacity: 1;
    }
    100% {

        -webkit-transform: rotate(-320deg);
        opacity: 0;
    }
}
@-webkit-keyframes spinoffPulse {

    0% {

        -webkit-transform: rotate(0deg);
    }
    100% {

        -webkit-transform: rotate(360deg);
    }
}

</style>
