import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bangongqu: [],
    addclass:[],
    url:'http://localhost:5500/public/upload/',
    urlt:'http://localhost:5500',
    videodata:[],
    selectclass: [],  //学生学习进度---对应学生信息
    selecttest: [],   //学生测试进度---对应学生信息
    selStu: 0,   //新增、编辑学生信息？  0：新增   1：编辑
    selInfo: [],  //选中的学生信息 ----判断是否有东西来看为  新增还是编辑
    selTeacher:[],  //选中的老师信息 ---
    pictureNum: 0,  //图片为新增还是修改？  0：新增   1：修改
    pictureInfo:0,  //轮播图的选中的信息
    noticeNum:0,  //通知栏为新增还是修改？  0：新增   1：修改
    noticeInfo:0,  //选中的通知栏信息
  },
  getters: {
  },
  mutations: {
    changeInfo1: function (state, data) {
      state.bangongqu = data;
    },
     changeInfo2: function (state, data) {
      state.addclass = data;
    },
     changeInfo3: function (state, data3) {

      state.videodata = data3;
    },
     //查看学习进度----
    changeInfo4: function (state, data) {

      state.selectclass = data;
    },
    //查看测试进度
    changeInfo5: function (state, data) {

      state.selecttest = data;
    },

    //新增、编辑学生信息
    changeStu: function (state, data) {
      state.selStu = data;
    },
    //选中的学生信息
    changeStuInfo: function (state, data) {
      state.selInfo = data;
    },
    //选中的老师信息
    changeTeacher: function (state, data) {
      state.selTeacher = data;
    },
    //轮播图
    changePicNum: function (state, data) {
      state.pictureNum = data;
    },
    //轮播图----选中的信息
    pictureInfo: function (state, data) {
      state.pictureInfo = data;
    },
    //通知栏
    changeNotice: function (state, data) {
      state.noticeNum = data;
    },
    //编辑通知栏---里面选中的 消息id
    noticeInfo: function (state, data) {
      state.noticeInfo = data;
      console.log(state.noticeInfo)
    },

  },
  actions: {
  },
  modules: {
  }
})
