// data:初始的所有表格数据  showarry：表格显示的所有数据   attr：表格的哪列  rev：升序还是降序

//判断升降序   
function sortFun(data,showarry, attr, rev) {
    //第一个参数传入info里的prop表示排的是哪一列，第二个参数是升还是降排序
    //升序
    if (rev == 'ascending') {
    //讲表格初始所有数据，传递给函数，重新排序后给表格显示的所有数据
        showarry = sortUp(data, attr)
        return showarry;
    } 
    //降序
    showarry = sortDown(data, attr)

    return showarry;
}





//数字-中文首字母-英文大写-英文小写
    //升序。。。
function sortUp(arry, field) {
    var rankArr = []
    // 数字0开头
    var zeroArr = arry.filter((ele, index) => {
    if (Number(ele[field].substr(0, 1)) === 0) {
        return !isNaN(Number(ele[field].substr(0, 1)))
    }
    })
    // 数字0开头排序
    zeroArr = zeroArr.sort((a, b) => {
    // return parseInt(a.name) - parseInt(b.name)
    return (
        a[field].replace(/[^0-9]/gi, '') - b[field].replace(/[^0-9]/gi, '')
    )
    })
    rankArr = zeroArr
    // 数字非0开头
    var numArr = arry.filter((ele, index) => {
    if (Number(ele[field].substr(0, 1)) !== 0) {
        return !isNaN(Number(ele[field].substr(0, 1)))
    }
    })
    // 数字非0开头排序
    numArr = numArr.sort((a, b) => {
    return (
        a[field].replace(/[^0-9]/gi, '') - b[field].replace(/[^0-9]/gi, '')
    )
    })
    // 汉字开头
    var reg = new RegExp('^[\u4e00-\u9fa5]')
    var wordArr = arry.filter((ele, index) => {
    return reg.test(ele[field].substr(0, 1))
    })
    // 汉字开头排序
    wordArr = wordArr.sort((a, b) => {
    return a[field].localeCompare(b[field])
    })
    // 大写字母开头
    var regUpper = /^[A-Z]+$/
    var upperArr = arry.filter((ele, index) => {
    return regUpper.test(ele[field].substr(0, 1))
    })
    // 大写字母开头排序
    upperArr = upperArr.sort((a, b) => {
    return a[field].localeCompare(b[field])
    })
    // 剩余其他的开头
    var otherArr = arry.filter((ele, index) => {
    return (
        isNaN(Number(ele[field].substr(0, 1))) &&
        !reg.test(ele[field].substr(0, 1)) &&
        !regUpper.test(ele[field].substr(0, 1))
    )
    })
    // 剩余其他的开头排序
    otherArr = otherArr.sort((a, b) => {
    return a[field].localeCompare(b[field])
    })
    arry = rankArr
    arry = arry.concat(numArr, wordArr, upperArr, otherArr)
    return arry
}
    
    //降序
function sortDown(arry, field) {
    var rankArr = []
    // 数字0开头
    var zeroArr = arry.filter((ele, index) => {
    if (Number(ele[field].substr(0, 1)) === 0) {
        return !isNaN(Number(ele[field].substr(0, 1)))
    }
    })
    // 数字0开头排序
    zeroArr = zeroArr.sort((a, b) => {
    // return parseInt(a.name) - parseInt(b.name)
    return (
        b[field].replace(/[^0-9]/gi, '') - a[field].replace(/[^0-9]/gi, '')
    )
    })
    rankArr = zeroArr
    // 数字非0开头
    var numArr = arry.filter((ele, index) => {
    if (Number(ele[field].substr(0, 1)) !== 0) {
        return !isNaN(Number(ele[field].substr(0, 1)))
    }
    })
    // 数字非0开头排序
    numArr = numArr.sort((a, b) => {
    return (
        b[field].replace(/[^0-9]/gi, '') - a[field].replace(/[^0-9]/gi, '')
    )
    })
    // 汉字开头
    var reg = new RegExp('^[\u4e00-\u9fa5]')
    var wordArr = arry.filter((ele, index) => {
    return reg.test(ele[field].substr(0, 1))
    })
    // 汉字开头排序
    wordArr = wordArr.sort((a, b) => {
    return b[field].localeCompare(a[field])
    })
    // 大写字母开头
    var regUpper = /^[A-Z]+$/
    var upperArr = arry.filter((ele, index) => {
    return regUpper.test(ele[field].substr(0, 1))
    })
    // 大写字母开头排序
    upperArr = upperArr.sort((a, b) => {
    return b[field].localeCompare(a[field])
    })
    // 剩余其他的开头
    var otherArr = arry.filter((ele, index) => {
    return (
        isNaN(Number(ele[field].substr(0, 1))) &&
        !reg.test(ele[field].substr(0, 1)) &&
        !regUpper.test(ele[field].substr(0, 1))
    )
    })
    // 剩余其他的开头排序
    otherArr = otherArr.sort((a, b) => {
    return b[field].localeCompare(a[field])
    })
    arry = rankArr
    arry = arry.concat(numArr, wordArr, upperArr, otherArr)
    return arry
}


export {sortFun,sortUp,sortDown}