import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: { name: 'login' }

  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/OnlineClasses/',
    name: 'OnlineClasses',
    component: () => import('../views/OnlineClasses.vue'),
    redirect: { name: 'SubjectManagement' },

    children: [
      {
        path: "SubjectManagement",//科目管理
        name: "SubjectManagement",
        component: () => import('../views/Subject/SubjectManagement.vue'),
      },
      {
        path: "AddSubject",//科目管理
        name: "AddSubject",
        component: () => import('../views/Subject/AddSubject.vue'),
      },

      {
        path: "NetClassManagement",//网课管理
        name: "NetClassManagement",
        component: () => import('../views/NetClass/NetClassManagement.vue'),
      }, 
      {
        path: "Class",//添加章节
        name: "Class",
        component: () => import('../views/NetClass/Class.vue'),
      },    
      {
        path: "Addclass",//添加真题
        name: "Addclass",
        component: () => import('../views/NetClass/Addclass.vue'),
      },  
      {
        path: "NetClaeeList",//添加真题
        name: "NetClaeeList",
        component: () => import('../views/NetClass/NetClaeeList.vue'),
      },   
      {
        path: "PoliticalHotSpot",//政治热点
        name: "PoliticalHotSpot",
        component: () => import('../views/PoliticalHotSpot/PoliticalHotSpot.vue'),
      },
      {
        path: "Richtext",//添加热点
        name: "Richtext",
        component: () => import('../views/PoliticalHotSpot/Richtext.vue'),
      },
      {
        path: "BoManagement",//真题管理
        name: "BoManagement",
        component: () => import('../views/BoManagement/BoManagement.vue'),
      },
      // {
      //   path: "LearnMaterials",//学习资料管理
      //   name: "LearnMaterials",
      //   component: () => import('../views/LearnMaterials/LearnMaterials.vue'),
      // },
      {
        path: "Rotationchart",//轮播图管理
        name: "Rotationchart",
        component: () => import('../views/HomeManager/Rotationchart.vue'),
      },
      {
        path: "PicturePage",//新增轮播图
        name: "PicturePage",
        component: () => import('../views/HomeManager/PicturePage.vue'),
      },
      {
        path: "Noticecolumn",//通知栏管理
        name: "Noticecolumn",
        component: () => import('../views/HomeManager/Noticecolumn.vue'),
      },
      {
        path: "NoticePage",//新增通知栏
        name: "NoticePage",
        component: () => import('../views/HomeManager/NoticePage.vue'),
      },
      {
        path: "StuCourse",//学生课程管理
        name: "StuCourse",
        component: () => import('../views/Student/StuCourse.vue'),
      },
      {
        path: "ViewLearn",//查看学习进度
        name: "ViewLearn",
        component: () => import('../views/Student/ViewLearn.vue'),
      },
      {
        path: "ViewTest",//查看测试进度
        name: "ViewTest",
        component: () => import('../views/Student/ViewTest.vue'),
      },
      {
        path: "StuInfo",//学生信息列表
        name: "StuInfo",
        component: () => import('../views/Student/StuInfo.vue'),
      },
      {
        path: "StuAdd",//新增学生
        name: "StuAdd",
        component: () => import('../views/Student/StuAdd.vue'),
      },
      {
        path: "TeacherSettings",//教师列表
        name: "TeacherSettings",
        component: () => import('../views/Teacher/TeacherSettings.vue'),
      },
      {
        path: "TeacherAdd",//新增教师
        name: "TeacherAdd",
        component: () => import('../views/Teacher/TeacherAdd.vue'),
      },
      {
        path: "BoManagement",//真题管理
        name: "BoManagement",
        component: () => import('../views/BoManagement/BoManagement.vue'),
      },
      {
        path: "Truetopic",//添加真题
        name: "Truetopic",
        component: () => import('../views/BoManagement/Truetopic.vue'),
      },

      {
        path: "Setupclass",//设置课程
        name: "Setupclass",
        component: () => import('../views/Student/Setupclass.vue'),
        
      },{
        path: "Selectclass",//设置课程
        name: "Selectclass",
        component: () => import('../views/Student/Selectclass.vue'),       
      }
      ,{
        path: "Systemsettingmy",//系统设置-个人资料
        name: "Systemsettingmy",
        component: () => import('../views/Systemsettingmy.vue'),       
      },
      {
        path: "Learningmaterials",//学习管理-学习资料管理
        name: "Learningmaterials",
        component: () => import('../views/Learningmaterials/Learningmaterials.vue'),       
      },
      {
        path: "LearnText",//学习管理-学习资料管理---富文本框
        name: "LearnText",
        component: () => import('../views/Learningmaterials/LearnText.vue'),       
      },
      {
        path: "Wordmanagement",//单词
        name: "Wordmanagement",
        component: () => import('../views/Learningmaterials/Wordmanagement.vue'),  
      },
      {
        path: "Dailypractice",//每日一练
        name: "Dailypractice",
        component: () => import('../views/Learningmaterials/Dailypractice.vue'),  
      },
      {
        path: "Examination",//考试大纲
        name: "Examination",
        component: () => import('../views/Learningmaterials/Examination.vue'),  
      },
      {
        path: "ExaminationAdd",//考试大纲-----添加编辑----富文本框
        name: "ExaminationAdd",
        component: () => import('../views/Learningmaterials/ExaminationAdd.vue'),  
      }
      
    ]
  }
]


const router = new VueRouter({
  routes
})
//全局路由前置守卫 --路由拦截
router.beforeEach((to, from, next) => {
  //next(path) 是一个函数  表示继续执行下一步，强制执行前往的path 路由
  //如果前往login 页面  则不需要token 直接进入就可以
  if (to.path === "/") return next();
  //获取token
  const TOKEN = window.sessionStorage.getItem("token");
  if (!TOKEN) {
    //token 不存在  name 意味着 没有进行登录  返回登录页面
    next({ path: "/" });
  }
  //已经 存在TOKEN  name 就可以进行
  next();
});
export default router
