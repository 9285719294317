import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/https'
import dayjs from 'dayjs';
import *as echarts from 'echarts';
import {sortFun,sortUp,sortDown } from './utils/sort'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.dayjs = dayjs;
Vue.prototype.$echarts = echarts;
Vue.prototype.$sortFun = sortFun;
Vue.prototype.$sortUp = sortUp;
Vue.prototype.$sortUp = sortDown;
new Vue({
  router,
  store, 
  render: h => h(App)
}).$mount('#app')
